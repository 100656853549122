import React, { useState } from 'react';
import { Server, Code, Database,  Shield, Zap, MinusCircle, PlusCircle,  HelpCircle, Network, GitBranch, Terminal, Lock, Activity, Workflow, Cloud, Settings, Layers, RefreshCw, Boxes, Cpu as CpuIcon, Radio, Webhook, Fingerprint, Key, Gauge, Binary, ChevronDown } from 'lucide-react';
import styled, { createGlobalStyle, keyframes } from 'styled-components';
import Register from "components/Authentification/modals/register";
import Header from 'components/Header/Header';
import FooterHome from '../FooterHome/FooterHome';
import Offers from './Offres';
import { Helmet } from 'react-helmet';
const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    background: #0F172A;
    color: #ffffff;
    font-family: 'JetBrains Mono', monospace;
  }
`;

const glitch = keyframes`
  0% {
    transform: translate(0);
  }
  20% {
    transform: translate(-2px, 2px);
  }
  40% {
    transform: translate(-2px, -2px);
  }
  60% {
    transform: translate(2px, 2px);
  }
  80% {
    transform: translate(2px, -2px);
  }
  100% {
    transform: translate(0);
  }
`;

const pulse = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(99, 102, 241, 0.4);
  }
  70% {
    box-shadow: 0 0 0 20px rgba(99, 102, 241, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(99, 102, 241, 0);
  }
`;

const MainContainer = styled.div`
  min-height: 100vh;
  background: #0F172A;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: 
      radial-gradient(circle at 20% 20%, rgba(99, 102, 241, 0.15) 0%, transparent 50%),
      radial-gradient(circle at 80% 80%, rgba(79, 70, 229, 0.15) 0%, transparent 50%);
    pointer-events: none;
  }
`;

const HeroSection = styled.section`
  min-height: 67vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 2rem;
  
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(45deg, rgba(99, 102, 241, 0.1), rgba(79, 70, 229, 0.1));
    z-index: 0;
  }
`;

const HeroContent = styled.div`
  max-width: 64rem;
  width: 100%;
  text-align: center;
  position: relative;
  z-index: 1;
`;

const Logo = styled.div`
  width: 150px;
  height: 150px;
  margin: 0 auto 2rem;
  background: linear-gradient(-45deg, #4F46E5, #6366F1, #4338CA);
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  animation: ${pulse} 2s infinite;

  svg {
    width: 80px;
    height: 80px;
    color: #ffffff;
    animation: ${glitch} 2s infinite;
  }

  &::before {
    content: '';
    position: absolute;
    inset: -2px;
    background: linear-gradient(-45deg, #4F46E5, #6366F1, #4338CA);
    border-radius: 18px;
    z-index: -1;
    opacity: 0.5;
    filter: blur(8px);
  }
`;

const HeroTitle = styled.h1`
  font-size: 4rem;
  font-weight: 700;
  margin: 2rem 0;
  color: #ffffff;
  text-shadow: 0 0 10px rgba(99, 102, 241, 0.5);
  position: relative;

  &::before {
    content: 'Missions pour les Freelances Backend';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: #6366F1;
    opacity: 0.8;
    animation: ${glitch} 5s infinite;
    clip-path: polygon(0 0, 100% 0, 100% 45%, 0 45%);
  }

  @media (max-width: 768px) {
    font-size: 2.5rem;
  }
`;

const HeroSubtitle = styled.p`
  font-size: 1.25rem;
  color: #94A3B8;
  margin-bottom: 2rem;
  line-height: 1.6;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
`;

const Button = styled.button`
  background: linear-gradient(135deg, #4F46E5, #6366F1);
  color: white;
  padding: 1.25rem 2.5rem;
  border-radius: 8px;
  font-family: 'JetBrains Mono', monospace;
  font-weight: 600;
  font-size: 1.125rem;
  border: none;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 0.75rem;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 0 30px rgba(99, 102, 241, 0.6);
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      90deg,
      transparent,
      rgba(255, 255, 255, 0.2),
      transparent
    );
    transition: 0.5s;
  }

  &:hover::before {
    left: 100%;
  }
`;

const Section = styled.section`
  padding: 8rem 2rem;
  position: relative;
  background: #1E293B;

  &:nth-child(odd) {
    background: #0F172A;
  }
`;

const SectionContent = styled.div`
  max-width: 72rem;
  margin: 0 auto;
`;

const SectionTitle = styled.h2`
  font-size: 3rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 1rem;
  color: #ffffff;
  text-shadow: 0 0 10px rgba(99, 102, 241, 0.5);

  svg {
    display: inline-block;
    vertical-align: middle;
    margin-right: 1rem;
    color: #6366F1;
  }
`;

const SectionSubtitle = styled.p`
  text-align: center;
  color: #94A3B8;
  font-size: 1.25rem;
  margin-bottom: 4rem;
  max-width: 48rem;
  margin-left: auto;
  margin-right: auto;
`;

const ServicesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-bottom: 4rem;
`;

const ServiceCard = styled.div`
  background: rgba(99, 102, 241, 0.05);
  border: 1px solid rgba(99, 102, 241, 0.1);
  border-radius: 12px;
  padding: 2.5rem;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 0 40px rgba(99, 102, 241, 0.2);
    border-color: #6366F1;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      90deg,
      transparent,
      rgba(99, 102, 241, 0.1),
      transparent
    );
    transition: 0.5s;
  }

  &:hover::before {
    left: 100%;
  }
`;

const ServiceIcon = styled.div`
  width: 80px;
  height: 80px;
  margin: 0 auto 2rem;
  background: linear-gradient(135deg, #4F46E5, #6366F1);
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    inset: -2px;
    background: inherit;
    border-radius: 14px;
    z-index: -1;
    opacity: 0.4;
    filter: blur(8px);
  }
`;

const ServiceTitle = styled.h3`
  font-size: 1.5rem;
  font-weight: 600;
  color: #ffffff;
  margin-bottom: 1rem;
  text-align: center;
`;

const ServiceDescription = styled.p`
  color: #94A3B8;
  line-height: 1.6;
  text-align: center;
`;

const StatsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
`;

const StatCard = styled.div`
  background: rgba(99, 102, 241, 0.05);
  border: 1px solid rgba(99, 102, 241, 0.1);
  border-radius: 12px;
  padding: 2rem;
  text-align: center;
  transition: all 0.3s;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 0 30px rgba(99, 102, 241, 0.2);
    border-color: #6366F1;
  }
`;

const StatValue = styled.div`
  font-size: 3rem;
  font-weight: 700;
  color: #6366F1;
  margin-bottom: 0.5rem;
  text-shadow: 0 0 10px rgba(99, 102, 241, 0.3);
`;

const StatLabel = styled.div`
  color: #94A3B8;
  font-size: 1.125rem;
`;

const MissionsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 1.5rem;
  margin-top: 2rem;
`;

const MissionCard = styled.div`
  background: rgba(99, 102, 241, 0.05);
  border: 1px solid rgba(99, 102, 241, 0.1);
  border-radius: 12px;
  padding: 1.5rem;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 0 30px rgba(99, 102, 241, 0.2);
    border-color: #6366F1;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      90deg,
      transparent,
      rgba(99, 102, 241, 0.1),
      transparent
    );
    transition: 0.5s;
  }

  &:hover::before {
    left: 100%;
  }
`;

const MissionHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
`;

const MissionIcon = styled.div`
  width: 40px;
  height: 40px;
  background: linear-gradient(135deg, #4F46E5, #6366F1);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
`;

const MissionDifficulty = styled.span`
  padding: 0.25rem 0.75rem;
  border-radius: 50px;
  font-size: 0.75rem;
  font-weight: 600;
  background: ${props => {
    switch (props.difficulty) {
      case 'Facile':
        return 'rgba(34, 197, 94, 0.2)';
      case 'Intermédiaire':
        return 'rgba(234, 179, 8, 0.2)';
      case 'Avancé':
        return 'rgba(239, 68, 68, 0.2)';
      default:
        return 'rgba(99, 102, 241, 0.2)';
    }
  }};
  color: ${props => {
    switch (props.difficulty) {
      case 'Facile':
        return '#22c55e';
      case 'Intermédiaire':
        return '#eab308';
      case 'Avancé':
        return '#ef4444';
      default:
        return '#6366F1';
    }
  }};
`;

const MissionTitle = styled.h4`
  font-size: 1.125rem;
  font-weight: 600;
  color: #ffffff;
  margin: 0.5rem 0;
`;

const MissionDescription = styled.p`
  color: #94A3B8;
  font-size: 0.875rem;
  line-height: 1.5;
`;

const MoreMissionsButton = styled(Button)`
  margin: 3rem auto 0;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: transparent;
  border: 1px solid #6366F1;
  
  &:hover {
    background: linear-gradient(135deg, #4F46E5, #6366F1);
  }

  svg {
    transition: transform 0.3s ease;
  }

  &:hover svg {
    transform: translateY(4px);
  }
`;
const FAQSection = styled(Section)`
  background: #0A1A1F;
`;

const FAQContainer = styled.div`
  max-width: 48rem;
  margin: 0 auto;
`;

const FAQItem = styled.div`
  border: 1px solid rgba(6, 182, 212, 0.2);
  border-radius: 12px;
  margin-bottom: 1rem;
  overflow: hidden;
  transition: all 0.3s;

  &:hover {
    border-color: #06B6D4;
  }
`;

const FAQHeader = styled.button`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;
  background: rgba(6, 182, 212, 0.05);
  border: none;
  cursor: pointer;
  color: #ffffff;
  font-size: 1.125rem;
  font-weight: 500;
  text-align: left;
  transition: all 0.3s;

  &:hover {
    background: rgba(6, 182, 212, 0.1);
  }

  svg {
    color: #06B6D4;
    transition: transform 0.3s;
  }

  &[aria-expanded="true"] svg {
    transform: rotate(180deg);
  }
`;

const FAQContent = styled.div`
  padding: ${props => props.isOpen ? '1.5rem' : '0 1.5rem'};
  max-height: ${props => props.isOpen ? '500px' : '0'};
  opacity: ${props => props.isOpen ? '1' : '0'};
  overflow: hidden;
  transition: all 0.3s;
  color: #94A3B8;
  line-height: 1.6;

  p {
    margin: 0;
    margin-bottom: 1rem;
  }

  ul {
    margin: 0;
    padding-left: 1.5rem;
  }

  li {
    margin-bottom: 0.5rem;
  }
`;


const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const faqs = [
  {
      question: "Quelle architecture API choisir entre REST et GraphQL ?",
      answer: "Le choix dépend de vos besoins spécifiques. REST est idéal pour des APIs simples avec des endpoints fixes et une forte mise en cache. GraphQL excelle dans les applications complexes nécessitant des requêtes flexibles et l'optimisation du trafic réseau. REST est plus mature avec un meilleur support des outils, tandis que GraphQL offre une meilleure expérience développeur et réduit l'over-fetching."
  },
  {
      question: "Comment garantir la sécurité et la performance des APIs ?",
      answer: "Nous implémentons une approche multi-niveaux : 1) Sécurité : OAuth2/JWT pour l'authentification, validation des entrées, rate limiting, WAF pour la protection DDoS, et chiffrement TLS 1.3. 2) Performance : Cache distribué Redis, CDN pour les assets statiques, optimisation des requêtes avec DataLoader, monitoring Prometheus/Grafana, et auto-scaling Kubernetes."
  },
  {
      question: "Quelles sont les meilleures pratiques pour la documentation API ?",
      answer: "Nous utilisons OpenAPI 3.0 (Swagger) pour REST et GraphQL Schema pour la documentation auto-générée. Cela inclut : exemples de requêtes/réponses, authentification, gestion des erreurs, rate limits, et environnements de test. La documentation est versionnée avec l'API et inclut des playgrounds interactifs pour tester les endpoints."
  },
  {
      question: "Comment gérer le versioning et la rétrocompatibilité des APIs ?",
      answer: "Nous appliquons le versioning sémantique (SemVer) avec plusieurs stratégies : 1) URI versioning (/v1/, /v2/) pour les changements majeurs, 2) Content negotiation pour les modifications mineures, 3) Feature flags pour les déploiements progressifs. Les changements breaking sont documentés et communiqués à l'avance, avec une période de dépréciation."
  },
  {
      question: "Quelle est votre approche pour les microservices ?",
      answer: "Notre architecture microservices utilise Docker pour la containerisation et Kubernetes pour l'orchestration. Nous implémentons un service mesh Istio pour la gestion du trafic, la sécurité et l'observabilité. Le monitoring inclut Prometheus/Grafana pour les métriques, Jaeger pour le tracing distribué, et ELK pour les logs centralisés."
  },
  {
      question: "Comment gérer les tests et le CI/CD pour les APIs ?",
      answer: "Notre pipeline de test comprend : 1) Tests unitaires avec Jest/Mocha, 2) Tests d'intégration avec Supertest, 3) Tests de charge avec k6, 4) Tests de sécurité avec OWASP ZAP. Le CI/CD utilise GitHub Actions/GitLab CI avec déploiement Blue/Green sur Kubernetes, et monitoring post-déploiement."
  },
  {
      question: "Comment optimiser une API existante pour les performances ?",
      answer: "Nous utilisons des techniques telles que la mise en cache, la pagination pour les grandes collections de données, l'optimisation des requêtes SQL, et l'utilisation de CDN pour le contenu statique afin d'améliorer les temps de réponse et réduire la charge sur le serveur."
  },
  {
      question: "Quelle est la place des tests de sécurité dans le développement d'APIs ?",
      answer: "Les tests de sécurité sont cruciaux pour détecter les vulnérabilités potentielles. Nous intégrons des tests réguliers dans notre pipeline CI/CD pour identifier et corriger les problèmes de sécurité tels que les injections SQL, XSS, et d'autres menaces."
  },
  {
      question: "Est-ce que l'utilisation de GraphQL nécessite plus de temps de développement que REST ?",
      answer: "Cela dépend du projet. GraphQL peut réduire le temps de développement initial en évitant la création de multiples endpoints pour différentes requêtes. Cependant, il peut nécessiter davantage d'efforts pour la mise en place de la documentation et des systèmes de validation."
  },
  {
      question: "Comment gérer l'authentification dans une API REST ?",
      answer: "L'authentification peut être gérée via des tokens OAuth2 ou JWT. Les requêtes d'API incluent le token dans l'en-tête Authorization, et le serveur valide ce token avant d'accorder l'accès aux ressources."
  },
  {
      question: "Quel est l'impact des appels API sur la latence d'une application ?",
      answer: "Chaque appel API peut ajouter une latence, en particulier si des appels multiples sont nécessaires pour charger une page. L'optimisation des requêtes et la réduction du nombre d'appels peuvent minimiser cet impact."
  },
  {
      question: "Quelles sont les meilleures pratiques pour le design d'API ?",
      answer: "Adoptez des conventions de nommage cohérentes, utilisez un versioning API, fournissez des réponses d'erreur claires, documentez toutes les fonctionnalités, et créez des tests d'endpoint pour garantir que chaque composant fonctionne comme prévu."
  },
  {
      question: "Utiliser des WebSockets pour une API REST est-il judicieux ?",
      answer: "Les WebSockets permettent une communication bidirectionnelle en temps réel. Pour des applications nécessitant des mises à jour instantanées (comme des chats ou des notifications), cela peut être une excellente option à combiner avec une API REST pour les autres fonctionnalités."
  },
  {
      question: "Que faire pour assurer la compatibilité entre versions d'API ?",
      answer: "Il est essentiel de maintenir une rétrocompatibilité en ajoutant des fonctionnalités sans supprimer celles existantes. En documentant soigneusement les modifications et en utilisant des outils de gestion de version, vous pouvez éviter des ruptures sur les utilisateurs existants."
  },
  {
      question: "Qu'est-ce que le rate limiting et pourquoi est-ce important ?",
      answer: "Le rate limiting est une méthode pour limiter le nombre de requêtes qu'un client peut faire à l'API dans une période donnée. Cela aide à prévenir les abus, à contrôler la charge du serveur et à garantir que les ressources sont disponibles pour tous les utilisateurs."
  },
  {
      question: "Comment intégrer des API tiers dans mon application ?",
      answer: "Pour intégrer des API tiers, commencez par lire la documentation pour comprendre leurs fonctionnalités et restrictions. Créez un client API dans votre application pour envoyer des requêtes et traiter les réponses. Assurez-vous de gérer les erreurs et les limitations de la API."
  },
  {
      question: "Comment suivre les appels API dans mon application ?",
      answer: "Nous intégrons des outils de monitoring comme Prometheus ou New Relic pour suivre les appels API, mesurer les temps de réponse, et détecter les anomalies. Cela nous permet d'optimiser les performances et de corriger rapidement les problèmes."
  },
  {
      question: "Quelle est la méthode pour gérer les données sensibles dans une API ?",
      answer: "Toutes les données sensibles doivent être chiffrées, que ce soit au repos ou en transit. L'utilisation de HTTPS pour les requêtes et le stockage chiffré des données dans les bases de données sont essentiels pour assurer la confidentialité et la sécurité."
  },
  {
      question: "Quels outils recommandez-vous pour tester une API ?",
      answer: "Des outils comme Postman pour les tests manuels, et des frameworks comme Jest ou Mocha pour les tests automatisés, sont essentiels pour garantir que vos APIs fonctionnent comme prévu."
  },
  {
      question: "Comment prioriser la mise en cache dans une API ?",
      answer: "Utilisez des en-têtes de cache pour contrôler la durée de vie des réponses et choisissez judicieusement les données à mettre en cache pour réduire la latence et charger plus rapidement les réponses. Les réponses communes comme celles des listes peuvent être mises en cache efficacement."
  },
  {
      question: "Comment assurer une bonne utilisation des ressources de l'API ?",
      answer: "Implémentez un monitoring efficace, utilisez des outils de profiling, et optimisez les requêtes pour garantir que chaque appel est nécessaire et efficace."
  },
  {
      question: "Quelles technologies modernes sont utilisées dans le développement d'APIs ?",
      answer: "Les technologies modernes incluent Node.js pour le développement rapide, GraphQL pour des requêtes flexibles, et des conteneurs Docker pour une orchestration efficace avec Kubernetes."
  },
  {
      question: "Comment gérer les problèmes de latence dans une API ?",
      answer: "Identifiez les requêtes lentes grâce à la collecte de métriques, effectuez des optimisations sur votre code et vos requêtes, et envisagez l'utilisation de cache pour réduire la latence."
  },
  {
      question: "Pourquoi est-il important d'établir des contrats d'API ?",
      answer: "Les contrats d'API décrivent les attentes claires sur les paramètres d'entrée, les types de sortie, et les erreurs possibles. Cela garantit la cohérence lors de l'intégration d'autres systèmes et réduit les risques de confusion."
  },
  {
      question: "Quelles sont les erreurs courantes à éviter lors du développement d'APIs ?",
      answer: "Les erreurs courantes comprennent des en-têtes de réponse manquants, des données non validées, le manque de documentation, et la non-prise en compte de la rétrocompatibilité des APIs."
  },
  {
      question: "Que faire en cas de panne de l'API ?",
      answer: "Assurez-vous d'avoir mis en place un système de monitoring et d'alerte. Analysez les logs pour identifier le problème, et implémentez des solutions pour rétablir le service rapidement tout en informant les utilisateurs de l'interruption."
  },
  {
      question: "Comment gérer les appels API dans une application mobile ?",
      answer: "Utilisez des techniques comme l'appel asynchrone pour éviter de bloquer l'interface utilisateur, implémentez des caches locaux pour réduire le nombre d'appels et améliorer les performances, et assurez-vous de gérer les retours d'erreur de manière élégante."
  },
  {
      question: "Comment établir un suivi des modifications de l'API ?",
      answer: "Utilisez un système de versionnement pour identifier facilement les changes, et documentez les modifications apportées à chaque version. Ceci aide les développeurs et les utilisateurs de l'API à s'ajuster aux nouvelles fonctionnalités et à éviter les incompatibilités."
  },
  {
      question: "Comment éduquer les développeurs à l'usage des APIs de votre entreprise ?",
      answer: "Proposez des sessions de formation, créez une documentation complète, et offrez des environnements de test ou des sandbox où ils peuvent expérimenter sans risque. Facilitez également les retours et questions via des forums ou des canaux de communication dédiés."
  }
];


function SiteBackend() {
  const [openModalRegister, setOpenModalRegister] = useState(false);
  const [openFAQs, setOpenFAQs] = useState({});

  const toggleFAQ = (index) => {
    setOpenFAQs(prev => ({
      ...prev,
      [index]: !prev[index]
    }));
  };


  const handleModalRegister = () => {
    setOpenModalRegister(true);
  };

  const handleCloseModalRegister = () => {
    setOpenModalRegister(false);
  };
  
  const handelSearchProjects = () => {
    window.location.href = `${process.env.REACT_APP_FRONTED_URL}/search/projects/category/DEVELOPER_BACKEND`;
  };


  const services = [
    {
      icon: <Database size={32} />,
      title: 'Architecture Microservices',
      description: 'Conception et implémentation d\'architectures scalables et résilientes.'
    },
    {
      icon: <Shield size={32} />,
      title: 'Sécurité & Performance',
      description: 'Protection des données et optimisation des performances serveur.'
    },
    {
      icon: <GitBranch size={32} />,
      title: 'API RESTful',
      description: 'Développement d\'APIs modernes avec documentation complète.'
    }
  ];

  const stats = [
    {
      value: '99.9%',
      label: 'Uptime Serveur'
    },
    {
      value: '<100ms',
      label: 'Temps de Réponse'
    },
    {
      value: '10x',
      label: 'Gain de Performance'
    },
    {
      value: '24/7',
      label: 'Monitoring'
    }
  ];

  const missions = [
    {
        icon: <Terminal size={20} />,
        title: "API RESTful Express",
        description: "Créer une API RESTful avec Express.js et documentation Swagger.",
        difficulty: "Facile",
        keywords: ["mission freelance developpeur", "mission freelance informatique"]
    },
    {
        icon: <Database size={20} />,
        title: "MongoDB Aggregation",
        description: "Implémenter des pipelines d'agrégation complexes.",
        difficulty: "Intermédiaire",
        keywords: ["mission freelance informatique", "mission freelance developpeur web"]
    },
    {
        icon: <Shield size={20} />,
        title: "Sécurité JWT",
        description: "Mettre en place l'authentification JWT avec refresh tokens.",
        difficulty: "Intermédiaire",
        keywords: ["mission freelance informatique", "freelance mission informatique"]
    },
    {
        icon: <Cloud size={20} />,
        title: "Serverless Functions",
        description: "Développer des fonctions serverless avec AWS Lambda.",
        difficulty: "Avancé",
        keywords: ["mission freelance", "missions freelance informatique"]
    },
    {
        icon: <Settings size={20} />,
        title: "CI/CD Pipeline",
        description: "Configurer un pipeline CI/CD avec GitHub Actions.",
        difficulty: "Intermédiaire",
        keywords: ["mission freelance en ligne", "trouver missions freelance"]
    },
    {
        icon: <Layers size={20} />,
        title: "Microservices",
        description: "Architecture microservices avec communication asynchrone.",
        difficulty: "Avancé",
        keywords: ["trouver mission freelance", "mission freelance dev"]
    },
    {
        icon: <RefreshCw size={20} />,
        title: "Cache Redis",
        description: "Implémenter un système de cache avec Redis.",
        difficulty: "Intermédiaire",
        keywords: ["mission freelance informatique", "mission full remote"]
    },
    {
        icon: <Boxes size={20} />,
        title: "Docker Compose",
        description: "Conteneuriser l'application avec Docker Compose.",
        difficulty: "Intermédiaire",
        keywords: ["mission freelance dev", "offre mission freelance"]
    },
    {
        icon: <CpuIcon size={20} />,
        title: "Worker Threads",
        description: "Optimiser les calculs avec Worker Threads.",
        difficulty: "Avancé",
        keywords: ["mission freelance informatique", "mission freelance developers"]
    },
    {
        icon: <Radio size={20} />,
        title: "WebSocket Real-time",
        description: "Chat en temps réel avec WebSocket.",
        difficulty: "Intermédiaire",
        keywords: ["mission freelance informatique", "missions freelance télétravail"]
    },
    {
        icon: <Webhook size={20} />,
        title: "Webhook System",
        description: "Système de webhooks pour notifications externes.",
        difficulty: "Intermédiaire",
        keywords: ["recherche mission freelance", "mission freelance developpeur"]
    },
    {
        icon: <Fingerprint size={20} />,
        title: "OAuth2 Integration",
        description: "Intégration OAuth2 multi-providers.",
        difficulty: "Avancé",
        keywords: ["mission freelance informatique", "mission developpeur freelance"]
    },
    {
        icon: <Key size={20} />,
        title: "API Rate Limiting",
        description: "Système de rate limiting pour API.",
        difficulty: "Intermédiaire",
        keywords: ["mission freelance dev", "freelance mission informatique"]
    },
    {
        icon: <Gauge size={20} />,
        title: "Performance Monitoring",
        description: "Monitoring avec Prometheus et Grafana.",
        difficulty: "Avancé",
        keywords: ["offre mission freelance", "mission freelance informatique"]
    },
    {
        icon: <Binary size={20} />,
        title: "GraphQL API",
        description: "API GraphQL avec Apollo Server.",
        difficulty: "Avancé",
        keywords: ["mission freelance web", "mission freelance dev"]
    },
    {
        icon: <Database size={20} />,
        title: "Database Migration",
        description: "Système de migration de base de données.",
        difficulty: "Intermédiaire",
        keywords: ["trouver mission freelance", "mission freelance informatique"]
    },
    {
        icon: <Shield size={20} />,
        title: "RBAC System",
        description: "Système de contrôle d'accès basé sur les rôles.",
        difficulty: "Avancé",
        keywords: ["missions freelance informatique", "freelance mission courte"]
    },
    {
        icon: <Network size={20} />,
        title: "Service Discovery",
        description: "Implémentation service discovery avec Consul.",
        difficulty: "Avancé",
        keywords: ["mission freelance dev", "mission en ligne freelance"]
    },
    {
        icon: <Lock size={20} />,
        title: "Encryption System",
        description: "Système de chiffrement des données sensibles.",
        difficulty: "Intermédiaire",
        keywords: ["mission freelance informatique", "mission freelance developer"]
    },
    {
        icon: <Activity size={20} />,
        title: "Event Sourcing",
        description: "Architecture event sourcing avec Kafka.",
        difficulty: "Avancé",
        keywords: ["freelance mission informatique", "offre mission freelance"]
    },

    // Nouveaux éléments
    {
        icon: <Zap size={20} />,
        title: "Mise en Place d'un Service Microservice",
        description: "Établissement d'un service microservice pour votre application.",
        difficulty: "Avancé",
        keywords: ["mission freelance dev", "mission freelance web"]
    }
];

  const displayedMissions =  missions ;

  return (
    <>
        <Helmet>
        <title>Plateforme Freelance Backend | Recruter des Freelances & Agences de Développement</title>
        <meta name="description" content="Découvrez les meilleures plateformes pour recruter des freelances backend, des experts en développement Java, PHP et Python, et trouver des missions freelance adaptées à vos besoins." />
        <meta
            name="keywords"
            content="missions en freelance, missions-freelance, offre mission freelance, mission de freelance, mission freelance developpeur, mission freelance paris, offre de mission freelance, trouver mission freelance informatique, trouver missions freelance, mission developpeur freelance, mission freelance developpeur web, mission freelance symfony, mission freelance java, mission freelance php, mission python freelance, mission freelance python, mission freelance dev, mission freelance informatique luxembourg, mission freelance informatique suisse"
        />
        <link rel="canonical" href="https://itgalaxy.io/missions-backend" />
        <meta property="og:title" content="Plateforme Freelance Backend | Recrutement de Freelances et Agences" />
        <meta property="og:description" content="Trouvez des freelances qualifiés en backend et des agences expertes pour le développement de solutions personnalisées." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://itgalaxy.io/missions-backend" />
        <meta property="og:locale" content="fr_FR" />
        <meta property="og:site_name" content="ItGalaxy.io" />
        <link rel="alternate" hreflang="fr" href="https://itgalaxy.io/missions-backend" />
        <link rel="alternate" hreflang="en" href="https://itgalaxy.io/missions-backend" />
        <script type="application/ld+json">
            {JSON.stringify({
                "@context": "https://schema.org",
                "@type": "Service",
                "name": "Plateforme Freelance Backend",
                "provider": {
                    "@type": "Organization",
                    "name": "ItGalaxy",
                    "address": {
                        "@type": "PostalAddress",
                        "addressLocality": "Paris",
                        "addressRegion": "Île-de-France",
                        "addressCountry": "FR"
                    }
                },
                "description": "Recrutement de freelances backend et d'agences de développement pour divers projets, y compris des missions à distance et en ligne.",
                "areaServed": ["France", "Paris", "Lyon", "Marseille", "Luxembourg", "Suisse"],
                "serviceType": ["Recrutement Freelance", "Développement Backend", "Consulting Technique"],
            })}
        </script>
    </Helmet>


     <Header />
     <GlobalStyle />
      <MainContainer>
        <HeroSection>
          <HeroContent>
            <Logo>
              <Server />
            </Logo>
            <HeroTitle>Missions pour les Freelances Backend </HeroTitle>
            <HeroSubtitle>
            Recrutement de freelances backend et d'agences de développement pour divers projets
            </HeroSubtitle>
            <Button onClick={handleModalRegister}>
              Trouvez un contrat Freelance
              <Terminal size={20} />
            </Button>
          </HeroContent>
        </HeroSection>

        <Section>
          <SectionContent>
            <SectionTitle>
              <Workflow />
              Missions Backend
            </SectionTitle>
            <SectionSubtitle>
              Relevez des défis techniques pour maîtriser le developpement backend
            </SectionSubtitle>
            <MissionsGrid>
              {displayedMissions.map((mission, index) => (
                <MissionCard key={index}>
                  <MissionHeader>
                    <MissionIcon>{mission.icon}</MissionIcon>
                    <MissionDifficulty difficulty={mission.difficulty}>
                      {mission.difficulty}
                    </MissionDifficulty>
                  </MissionHeader>
                  <MissionTitle>{mission.title}</MissionTitle>
                  <MissionDescription>{mission.description}</MissionDescription>
                </MissionCard>
              ))}
            </MissionsGrid>
              <ButtonContainer>
                <MoreMissionsButton onClick={handleModalRegister}>
                  Voir plus de missions
                  <ChevronDown size={20} />
                </MoreMissionsButton>
              </ButtonContainer>
          </SectionContent>
        </Section>

        <Section>
          <SectionContent>
            <SectionTitle>
              <Code />
              Solutions Backend
            </SectionTitle>
            <SectionSubtitle>
              Technologies de pointe pour des applications robustes
            </SectionSubtitle>
            <ServicesGrid>
              {services.map((service, index) => (
                <ServiceCard key={index}>
                  <ServiceIcon>{service.icon}</ServiceIcon>
                  <ServiceTitle>{service.title}</ServiceTitle>
                  <ServiceDescription>{service.description}</ServiceDescription>
                </ServiceCard>
              ))}
            </ServicesGrid>
          </SectionContent>
        </Section>

        <Section>
          <SectionContent>
            <SectionTitle>
              <Activity />
              Performances
            </SectionTitle>
            <SectionSubtitle>
              Des résultats mesurables pour votre infrastructure
            </SectionSubtitle>
            <StatsGrid>
              {stats.map((stat, index) => (
                <StatCard key={index}>
                  <StatValue>{stat.value}</StatValue>
                  <StatLabel>{stat.label}</StatLabel>
                </StatCard>
              ))}
            </StatsGrid>
          </SectionContent>
        </Section>


              <FAQSection>
                  <SectionContent>
                    <SectionTitle>
                      <HelpCircle />
                      Questions Fréquemment Posées
                    </SectionTitle>
                    <SectionSubtitle>
                      Tout ce que vous devez savoir sur l'embauche d'experts en développement Web
                    </SectionSubtitle>
                    <FAQContainer>
                      {faqs.map((faq, index) => (
                        <FAQItem key={index}>
                          <FAQHeader
                            onClick={() => toggleFAQ(index)}
                            aria-expanded={openFAQs[index]}
                            aria-controls={`faq-content-${index}`}
                          >
                            {faq.question}
                            {openFAQs[index] ? <MinusCircle size={20} /> : <PlusCircle size={20} />}
                          </FAQHeader>
                          <FAQContent
                            id={`faq-content-${index}`}
                            isOpen={openFAQs[index]}
                            role="region"
                            aria-labelledby={`faq-header-${index}`}
                          >
                            {faq.answer}
                          </FAQContent>
                        </FAQItem>
                      ))}
                    </FAQContainer>
                  </SectionContent>
                </FAQSection>

      </MainContainer>
      <Offers />
      <FooterHome page={"backend"} />
      {openModalRegister && (
      <Register
          openModalRegister={openModalRegister}
          setOpenModalRegister={setOpenModalRegister}
          handleModalRegister={handleCloseModalRegister}
          switchBetweenModals={false}
          proxy={"marketplace"}
        />)}

    </>
  );
}

export default SiteBackend;